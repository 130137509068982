import "./src/css/index.css"
import Cookies from "universal-cookie";

export const onClientEntry = () => {
  // disable tracking by default
  window[`ga-disable-${process.env.GATSBY_GA_TRACKING_ID}`] = true;

  const cookies = new Cookies();
  const cookieConsent = cookies.get(process.env.GATSBY_ANALYTICS_CONSENT_COOKIE);

  // renew expiration
  if (cookieConsent !== undefined) {
    window[`ga-disable-${process.env.GATSBY_GA_TRACKING_ID}`] = (cookieConsent === 'false');
    const oneYearFromNow = new Date();
    oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);
    cookies.set(process.env.GATSBY_ANALYTICS_CONSENT_COOKIE, cookieConsent, {
      expires: oneYearFromNow
    });
  }
}
